import * as React from "react";
import css from "styled-jsx/css";
import { connect } from "react-redux";
import { Layout, Dropdown, Avatar, Menu, Button } from "antd";
import { DownOutlined, MoonOutlined, MoonFilled } from "@ant-design/icons";
import { Link, NavLink, withRouter } from "react-router-dom";
import { loadUser } from "@app/redux/actions";
import { LoadingPage } from '@app/components/core/loading';
import { LocalStore } from "@app/utils/local-storage";
import { envName } from "@app/configs";
import AppsIcon from '@app/resources/images/apps_icon.svg';
import SelectedAppsIcon from '@app/resources/images/selected_apps_icon.svg';
import SelectedAppsIconDark from '@app/resources/images/selected_apps_icon_dark.svg';
import PWAIcon from '@app/resources/images/pwa_icon.svg';
import SelectedPWAIcon from '@app/resources/images/selected_pwa_icon.svg';
import SelectedPWAIconDark from '@app/resources/images/selected_pwa_icon_dark.svg';
import SenderIcon from '@app/resources/images/sender_icon.svg';
import SelectedSenderIcon from '@app/resources/images/selected_sender_icon.svg';
import SelectedSenderIconDark from '@app/resources/images/selected_sender_icon_dark.svg';
import HomeIcon from '@app/resources/images/home_icon.svg';
import SelectedHomeIcon from '@app/resources/images/selected_home_icon.svg';
import SelectedHomeIconDark from '@app/resources/images/selected_home_icon_dark.svg';
import UserIcon from '@app/resources/images/user_icon.svg';
import SelectedUserIcon from '@app/resources/images/selected_user_icon.svg';
import SelectedUserIconDark from '@app/resources/images/selected_user_icon_dark.svg';
import ServerIcon from '@app/resources/images/server_icon.svg';
import SelectedServerIcon from '@app/resources/images/selected_server_icon.svg';
import SelectedServerIconDark from '@app/resources/images/selected_server_icon_dark.svg';
import PushIcon from '@app/resources/images/push_icon.svg';
import SelectedPushIcon from '@app/resources/images/selected_push_icon.svg';
import SelectedPushIconDark from '@app/resources/images/selected_push_icon_dark.svg';
import AdsIcon from '@app/resources/images/ads_icon.svg';
import SelectedAdsIcon from '@app/resources/images/selected_ads_icon.svg';
import SelectedAdsIconDark from '@app/resources/images/selected_ads_icon_dark.svg';
import SubsIcon from '@app/resources/images/subs_icon.svg';
import SelectedSubsIcon from '@app/resources/images/selected_subs_icon.svg';
import SelectedSubsIconDark from '@app/resources/images/selected_subs_icon_dark.svg';
import LandingIcon from '@app/resources/images/landing_icon.svg';
import SelectedLandingIcon from '@app/resources/images/selected_landing_icon.svg';
import SelectedLandingIconDark from '@app/resources/images/selected_landing_icon_dark.svg';

const { Header, Content } = Layout;

const Action = ({ logout, userName = '', isDarkMode }) => (
  <Dropdown
    menu={{
      items: [
        {
          key: '1',
          label: <Link to="/profile">Edit profile</Link>,
        },
        {
          key: '2',
          label: 'Log out',
          onClick: logout,
        }
      ]}}
    trigger={['click']}
  >
    <div className="ant-dropdown-link flex items-center" onClick={(e) => e.preventDefault()}>
      <span style={{ marginLeft: 14 }} className="flex items-center">
        <span className="user-name" style={{ paddingRight: 32 }}>{userName}</span>
        <DownOutlined width={24} height={24} />
      </span>
    </div>
  </Dropdown>
);

const styles = css.global`
  .undefined {
    padding: 0 !important;
  }
  .site-layout-background.header {
    height: 56px;
    border-bottom: solid 1px var(--second-color);
    background-color: var(--background-color);
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header-actions {
    display: flex;
    align-items: center;
    gap: 16px; /* Adjust the gap as needed */
  }

  .header-actions .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    color: #9e9e9e;
  }

  .header-actions .ant-btn:hover {
    color: var(--primary-text-color);
  }

  .header .user-name {
    font-size: 13px;
    line-height: 1.38;
    letter-spacing: 0.3px;
  }
  .header .my-avatar {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 40px;
  }
  .header .logo {
    margin-right: 37px;
  }
  .header .logo img {
    width: 110px;
  }
  .header .list-menu {
    display: flex;
    align-items: center;
    flex: 1;
  }
  .header .list-menu li {
    list-style: none;
  }
  .header .list-menu li a {
    font-size: 10px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 1.54px;
    position: relative;
    padding: 0 18px;
    height: 56px;
    display: flex;
    align-items: center;
    color: #9e9e9e;
  }
  .header .list-menu li a i {
    font-size: 16px!important;
    color: #9e9e9e;
    margin-right: 13px;
  }
  .header .list-menu li a.active {
    color: var(--primary-text-color);
  }
  .header .list-menu li a.active i {
    color: var(--primary-text-color);
  }
  .header .list-menu li a.active:after {
    width: 100%;
    height: 3px;
    background-color: var(--primary-color);
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

class DefaultMain extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  logOut = () => {
    const { history, loadUser } = this.props;
    LocalStore.local.remove(`${envName}-uuid`);
    loadUser();
  };

  isSelected = (name) => location.pathname.indexOf(name) > -1

  getProfile = () => { };

  render() {
    const { children, user, isDarkMode, toggleTheme } = this.props;

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Layout className="site-layout">
          {user?.type === "admin" && (
            <Header className="site-layout-background header">
              <div className="logo">
                <Link to={'/'}>
                  <img src={isDarkMode ? '/images/logo_dark.png' : '/images/logo.png'} alt="" />
                </Link>
              </div>
              <ul className="list-menu">
                <li>
                  <NavLink exact to="/" className="uppercase">
                    <img src={this.isSelected('/') && location.pathname.length === 1 ? (isDarkMode ? SelectedAppsIconDark : SelectedAppsIcon) : AppsIcon} style={{ width: 24, height: 24 }} />
                    <div style={{ marginTop: 3 }}>Apps</div>
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink exact to="/dashboard" className="uppercase">
                    <img src={this.isSelected('/dashboard') ? (isDarkMode ? SelectedHomeIconDark : SelectedHomeIcon) : HomeIcon} style={{ width: 24, height: 24 }} />
                    <div style={{ marginTop: 3 }}>Dashboard</div>
                  </NavLink>
                </li> */}
                <li>
                  <NavLink exact to="/pwa" className="uppercase">
                    <img src={this.isSelected('/pwa') ? (isDarkMode ? SelectedPWAIconDark : SelectedPWAIcon) : PWAIcon} style={{ width: 24, height: 24 }} />
                    <div style={{ marginTop: 3 }}>PWA</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/sender" className="uppercase">
                    <img src={this.isSelected('/sender') ? (isDarkMode ? SelectedSenderIconDark : SelectedSenderIcon) : SenderIcon} style={{ width: 24, height: 24 }} />
                    <div style={{ marginTop: 3 }}>Sender</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/users" className="uppercase">
                    <img src={this.isSelected('/users') ? (isDarkMode ? SelectedUserIconDark : SelectedUserIcon) : UserIcon} style={{ width: 24, height: 24 }} />
                    <div style={{ marginTop: 3 }}>Users</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/server" className="uppercase">
                    <img src={this.isSelected('/server') ? (isDarkMode ? SelectedServerIconDark : SelectedServerIcon) : ServerIcon} style={{ width: 24, height: 24 }} />
                    <div style={{ marginTop: 3 }}>Servers</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/landing" className="uppercase">
                    <img src={this.isSelected('/landing') ? (isDarkMode ? SelectedLandingIconDark : SelectedLandingIcon) : LandingIcon} style={{ width: 24, height: 24 }} />
                    <div style={{ marginTop: 3 }}>Landing</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/subs" className="uppercase">
                    <img src={this.isSelected('/subs') ? (isDarkMode ? SelectedSubsIconDark : SelectedSubsIcon) : SubsIcon} style={{ width: 24, height: 24 }} />
                    <div style={{ marginTop: 3 }}>Subscriptions</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/ads" className="uppercase">
                    <img src={this.isSelected('/ads') ? (isDarkMode ? SelectedAdsIconDark : SelectedAdsIcon) : AdsIcon} style={{ width: 24, height: 24 }} />
                    <div style={{ marginTop: 3 }}>Ads</div>
                  </NavLink>
                </li>
              </ul>
              <div className="header-actions">
                <Button 
                  icon={isDarkMode ? <MoonFilled /> : <MoonOutlined />}
                  onClick={toggleTheme}
                />
                <Action
                  userName={`${user?.firstname || ""}`}
                  logout={this.logOut}
                  isDarkMode={isDarkMode}
                />
              </div>
            </Header>
          )}
          {user?.type === "dev" && (
            <Header className="site-layout-background header">
              <div className="logo">
                <Link to={'/'}>
                  <img src={isDarkMode ? "/images/logo.png" : "/images/logo_dark.png"} alt="" />
                </Link>
              </div>
              <ul className="list-menu">
                <li>
                  <NavLink exact to="/" className="uppercase">
                    <img src={this.isSelected('/') && location.pathname.length === 1 ? SelectedAppsIcon : AppsIcon} style={{ width: 24, height: 24 }} />
                    <div style={{ marginTop: 3 }}>Apps</div>
                  </NavLink>
                </li>
              </ul>
              <div className="header-actions">
              <Button 
                  icon={isDarkMode ? <MoonFilled /> : <MoonOutlined />}
                  onClick={toggleTheme}
                />
                <Action
                  userName={`${user?.firstname || ""}`}
                  logout={this.logOut}
                />
              </div>
            </Header>
          )}
          <Content
            className={`site-layout-background ${!user ? "undefined" : ""}`}
            style={{
              minHeight: 280,
              padding: "24px 74px",
            }}
          >
            {children}
          </Content>
        </Layout>
        <style jsx>{styles}</style>
        {this.state.loading && <LoadingPage />}
      </Layout>
    );
  }
}

const mapDispatchToProps = {
  loadUser,
};

const mapStatesToProps = (states) => ({
  user: states.global.user,
});

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(withRouter(DefaultMain));
