import React from "react";
import { Button } from "antd";
import css from 'styled-jsx/css'

const styles = css.global`
  .core-button {
    min-width: 115px;
    height: 32px;
    border-radius: 4px;
    padding: 0 15px !important;
    outline: none !important;
    box-shadow: none !important;
    border: none;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 1.54px;
    text-align: center;
    text-transform: uppercase;
  }

  .core-button.primary {
    background-color: var(--primary-color);
    color: #e5e5e5;
  }

  .core-button.primary:hover,
  .core-button.primary:focus,
  .core-button.primary:active {
    background-color: rgba(153, 51, 51, 0.7);
    color: var(--third-color);
  }

  .core-button.standard {
    background-color: var(--primary-text-color);
    color: var(--third-color);
  }

  .core-button.standard:hover,
  .core-button.standard:focus,
  .core-button.standard:active {
    background-color: rgba(117, 117, 117, 0.7);
    color: var(--third-color);
  }

  .core-button.border {
    border: solid 1px var(--second-color);
    background-color: var(--background-color);
    color: var(--primary-text-color);
  }

  .core-button.border:hover,
  .core-button.border:focus,
  .core-button.border:active {
    background-color: rgba(117, 117, 117, 0.7);
    color: var(--third-color);
  }

  .core-button.gray {
    background-color: var(--third-color-30);
    color: var(--primary-text-color);
  }

  .core-button.gray:hover,
  .core-button.gray:focus,
  .core-button.gray:active {
    background-color: rgba(117, 117, 117, 0.7);
    color: var(--third-color);
  }

  .core-button.ghost {
    background-color: var(--background-color);
    color: var(--primary-text-color);
  }

  .core-button.ghost:hover,
  .core-button.ghost:focus,
  .core-button.ghost:active {
    background-color: var(--primary-text-color);
    color: var(--third-color);
  }

  .core-button.link {
    background-color: var(--background-color);
    color: var(--primary-color);
  }

  .core-button.link:hover,
  .core-button.link:focus,
  .core-button.link:active {
    background-color: var(--background-color);
    color: var(--primary-color);
  }

  .core-button.icon {
    padding: 0 !important;
    width: 24px;
    height: 24px;
    background-color: var(--second-color);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .core-button.icon:hover,
  .core-button.icon:focus,
  .core-button.icon:active {
    background-color: var(--second-color);
  }
`;

const UIButton = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      className={`${props?.className || ''} core-button`}>
      {children}
      <style jsx>{styles}</style>
    </Button>
  );
}

UIButton.defaultProps = {
  label: "Button"
}

export default UIButton;
